import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  es: {
    translation: {
      common: {
        cancel: 'Cancelar',
        help: 'Ayuda',
        search_marketplaces: 'Buscar tienda',
        search: 'Buscar',
        privacy_cookies_policy: 'Política de privacidad',
        legal_advise: 'Aviso legal',
        terms_conditions: 'Condiciones de compra',
        cookies_policy: 'Política de cookies',
        cookies_settings: 'Configuración de cookies',
        rsc_cert: 'Certificado sello confianza',
        rsc_cert_mobile: 'Consulta aquí el certificado sello confianza',
        download_app_or_extension: 'Descarga la app o la extensión de Chrome',
        download_app: 'Descarga la app',
        menu: 'Menú',
        legal: 'Legal',
        loading: 'Cargando',
        custom_order: 'Pedido personalizado',
        custom_order_new_item: 'Añade aquí la url del nuevo artículo',
        marketplace: 'Tienda',
        marketplaces_not_found: 'No hemos encontrado marcas',
        yes: 'Sí',
        no: 'No',
        no_idk: 'No / No sé',
        write_to_search: 'Escribe para buscar',
        sequra_buy_msg: 'Para financiar tu compra, elige la opción \'SEQURA\' en el método de pago.',
        accept_terms_on_buy: 'Al pulsar en el botón de \'PAGAR\', está aceptando los términos y condiciones y confirma el pago.',
        i_accept: 'Acepto los ',
        terms_and_conditions: 'términos y condiciones',
        i_have_read_and_accept: 'He leído y acepto la ',
        privacy_policy: 'Política de Privacidad',
        receive_comercial_info: 'Quiero recibir comunicaciones comerciales de Syniva',
        import: 'Importar',
        import_success: 'Archivo importado correctamente',
        import_error: 'Error al importar el archivo',
        import_no_data: 'No hay datos a importar',
        download_import_template: 'Descargar plantilla Excel',
        edit: 'Editar',
        download_file: 'Descargar documento',
        upload_file: 'Subir documento',
        invite_a_friend: 'Invita a un amigo'
      },
      filter: {
        show_categories: 'Mostrar categorías',
      },
      error: {
        error: 'Error',
      },
      search_marketplaces: {
        custom_order_message: '¿No encuentras la tienda en la que quieres comprar?',
        custom_order_request: '¡Haz un pedido personalizado!',
        results: 'Resultado(s)',
        related: 'Relacionado(s)',
      },
      button: {
        all_marketplaces: 'Todas las tiendas',
        see_all_marketplaces: 'Ver todas las tiendas',
        custom_order: 'Pedido personalizado',
        contact: 'Contacto',
        add_to_cart: 'Añadir al carrito',
        go_to_cart: 'Ver carrito',
        new_item: '+ Añadir otro artículo'
      },
      custom_order: {
        info_1: '¿No encuentras la tienda en la que quieres comprar?',
        info_2: 'Utiliza esta opción en la que tu mismo, con el enlace del producto, serás capaz de indicarnos qué quieres comprar con Syniva, para que procesemos tu compra lo antes posible',
        info_3: 'Copia y pega el enlace exacto a la página del producto que quieres comprar',
        info_other_item: 'Si quieres puedes buscar y agregar otro artículo a tu pedido personalizado',
        url_in_black_list: 'Esta tienda ha sido reportada como dudosa, si quieres proseguir con tu pedido ponte en contacto con',
        search_product: 'Buscar este producto',
        search_result_title: 'Resultado de la búsqueda',
        product_name: 'Nombre del producto',
        product_description: 'Descripción producto',
        product_etc: 'Color, talla, tamaño, características detalladas',
        no_description: 'No tiene características',
        product_price: 'Precio en la tienda',
        expense_send_to_madrid: '¿Tiene esta tienda online gastos de envío a Madrid? (CP 28823)',
        has_accessories: '¿Tú pedido tiene ropa, calzado, bolsos o complementos?',
        units: 'Unidades',
        error_fetching_product: 'Ha ocurrido un error al buscar el producto',
        item_deleted: 'Se eliminó el articulo correctamente',
        expense_send_to_madrid_price: 'Precio de los gastos de envío',
        error_add_to_cart: 'Ha ocurrido un error al añadir el producto al carrito',
        item_added_to_cart: 'El producto se añadió al carrito',
        disclaimer: 'Si el precio del producto no es correcto o cambia antes de que procesemos la compra, nos pondremos en contacto contigo para que abones la diferencia',
        empty_field: 'Este campo no puede estar vacío.'
      },
      pages: {
        home: {
          title: 'Syniva'
        },
        login: {
          title: 'Inicia sesión',
          email: 'Email',
          password: 'Contraseña',
          sign_in: 'Entrar',
          sign_in_with_google: 'Acceder con Google',
          sign_in_with_facebook: 'Acceder con Facebook',
          forgot_password: '¿Has olvidado tu contraseña?',
          sign_up: 'Regístrate',
          sign_up_message: '¿No tienes cuenta?',
          something_wrong: 'Algo salió mal. Prueba más tarde o ponte en contacto con nosotros.',
          email_not_confirmed: 'Debe confirmar su correo electrónico para poder iniciar sesión.',
          incorrect_credentials: 'El usuario y/o contraseña no son correctos.',
          fill_out_email_for_reset: 'Indíca tu email en el campo de arriba y te enviaremos un enlace para que puedas restablecer tu contraseña.',
          reset_password_email_sent: 'Te hemos enviado un correo con indicaciones para que puedas restaurar tu contraseña.',
          checking: 'Comprobando...',
        },
        register_success: {
          title: '¡Estas a un solo paso!',
          message: 'Te hemos enviado un correo para que puedas confirmar tu cuenta. Una vez confirmada podrás disfrutar de SYNIVA.',

        },
        my_account: {
          title: 'Mi cuenta',
          name: 'Nombre',
          email: 'Email',
          addresses: 'Direcciones',
          reset_password: 'Cambiar mi contraseña',
          logout: 'Salir',
          save: 'Guardar',
          pending_actions: 'Acciones pendientes',
          delete_account: 'Eliminar cuenta',
          personal_info: 'Datos personales',
          billing_info: 'Datos de facturación',
          same_shipping_billing_data: 'Copiar datos personales en datos de facturación',
          change_password: 'Cambiar contraseña',
          no_items_to_show: 'No hay elementos que mostrar',
          payment_summary: 'Resumen de pago',
          confirm_cancel_order: '¿Confirma que desea cancelar este pedido?',
          cancel_order_requested: 'Cancelación solicitada',
          confirm_return_order: '¿Confirma que desea devolver este pedido?',
          return_order_requested: 'Devolución solicitada',
          supplements_info: 'Información importante sobre el pedido',
          estimated_costs_info_1: 'Aviso presupuesto',
          estimated_costs_info_2: 'Presupuesto volumétrico',
          estimated_costs_info_3: 'Presupuesto volumétrico aceptado',
          estimated_costs_info_4: 'Presupuesto cancelado',
          estimated_costs: {
            title: 'Información de presupuesto',
            budgetAdvise: 'Estamos procesando tu pedido y vemos que el bulto probablemente exceda de los kilos volumétricos incluidos en el método del envío que has elegido. Vamos a contactar con el proveedor para ver si nos puede facilitar esta información. En cuanto la tengamos, nos pondremos en contacto nuevamente para darte una estimación aproximada y decidas si continuar o no con tu pedido.',
            budgetInfo: 'Según la información que disponemos tu paquete tiene un peso de {{weight}}kg y unas medidas {{length}}cm, {{height}}cm, {{depth}}cm y por lo tanto un suplemento volumétrico de {{supplement}}€.',
            budgetInfoAdd: 'Ahora NO tendrás que abonar nada, una vez recibamos tu pedido en nuestro almacén, confirmaremos las medidas y peso definitivo y te enviaremos el enlace de pago para que lo abones. Si estás de acuerdo con el presupuesto y quieres continuar con tu pedido pincha en ACEPTAR. Si no estás de acuerdo y quieres cancelar tu pedido, pincha en CANCELAR.',
            budgetInfoAccepted: '¡Has aceptado el presupuesto! una vez recibamos tu pedido en nuestro almacén, confirmaremos las medidas y peso definitivo y te enviaremos el enlace de pago para que lo abones.',
            budgetInfoCanceled: 'Has solicitado la cancelación de tu pedido, en los próximos días recibirás el reembolso íntegro del mismo y método de pago que escogiste al realizar el pedido.',
            accept: 'ACEPTAR',
            cancel: 'CANCELAR'
          },
          supplements: {
            markAsRead: 'Leído',
            supplements_info_0: 'ajustes pendientes en tu pedido',
            supplements_info_1: 'Tienes un ajuste',
            supplements_info_2: 'por el importe de',
            supplements_refund_info_1: 'Tienes un reembolso',
            supplements_refund_info_2: 'por un importe de',
            supplements_status_0: 'Pendiente de pago',
            supplements_status_6: 'Pagado',
            supplement_volumetric: 'Volumétrico',
            priceChange: 'Cambio de precio',
            igic: 'Ajuste de IGIC',
            shippingInsurancePrice: 'Ajuste de seguro',
            transportToPeninsula: 'Transporte a península',
            transportSupplement: 'Suplemento de transporte',
            reducedIva: 'Ajuste IVA (IVA REDUCIDO)',
            reducedIvaSecondHand: 'Ajuste IVA (SEGUNDA MANO/PARTICULAR)',
            reducedIvaBreakdown: 'Ajuste IVA (NO DESGLOSE IVA)',
            reducedIvaEU: 'Ajuste IVA (ENVÍO FUERA UE)',
            reducedIvaShipment: 'Ajuste IVA (CAMBIO MÉTODO ENVÍO)',
            other: 'Otro',
            tariff: 'AIEM',
            unified: 'Unificado',
            penalization: 'Gastos gestión',
            refund: 'Abono',
            refundTransport: 'Abono por ajuste de transporte',
            refundStore: 'Abono de cupón descuento de tienda',
            refundByCancelation: 'Abono por cancelación',
            supplement_template_volumetric_1: 'Hemos comprobado las dimensiones de tu pedido y tienen un peso volumétrico de',
            supplement_template_volumetric_2: ' kg. Para poder prepararlo y entregártelo necesitamos que abones previamente el suplemento de',
            supplement_template_volumetric_3: '€ pues excede los 4kg volumétricos abonado en tu pago inicial.',
            supplement_template_reducedIva_1: 'Hemos comprobado tu pedido, pero uno o algunos de los productos que has comprado tiene IVA reducido.',
            supplement_template_reducedIva_2: '',
            supplement_template_reducedIvaSecondHand_1: 'Hemos comprobado tu pedido, pero vemos que el producto que has comprado es de segunda mano o de un particular. En estos casos, estos productos están exentos de IVA por lo que no podemos descontarlo de tu precio Syniva.',
            supplement_template_reducedIvaSecondHand_2: '',
            supplement_template_reducedIvaBreakdown_1: 'Hemos comprobado tu pedido, pero vemos que el proveedor o la tienda del producto que has comprado no factura con desglose del IVA por lo que no podemos descontarlo del precio Syniva.',
            supplement_template_reducedIvaBreakdown_2: '',
            supplement_template_reducedIvaEU_1: 'Hemos comprobado tu pedido, pero vemos que el producto que has comprado es de una tienda o marca que se encuentra fuera de la UE. Estas tiendas están exentas de facturar con IVA, por lo que no podemos descontarlo del precio Syniva.',
            supplement_template_reducedIvaEU_2: '',
            supplement_template_reducedIvaShipment_1: 'Hemos comprobado tu pedido, pero vemos que tras aplicar el cambio de método de envío el pedido tiene una alteración en el valor el IVA, por lo que tenemos que ajustar el precio Syniva.',
            supplement_template_reducedIvaShipment_2: '',
            supplement_template_tariff_1: 'Hemos comprobado tu pedido, pero vemos que el producto que has comprado tiene arancel de importación a Canarias.',
            supplement_template_tariff_2: '',
            supplement_template_igic_1: 'Hemos comprobado tu pedido, pero vemos que el producto que has comprado tiene una variación de IGIC.',
            supplement_template_igic_2: '',
            supplement_template_shippingInsurancePrice_1: 'Hemos comprobado tu pedido, pero vemos que el producto que has comprado tiene una variación en el seguro.',
            supplement_template_shippingInsurancePrice_2: '',
            supplement_template_penalization_1: 'Suplemento de penalización, por un importe de',
            supplement_template_penalization_2: '',
            supplement_template_transportToPeninsula_1: 'Hemos comprobado tu pedido y vemos que la marca o tienda aplica un coste de envío hasta nuestro almacén de península.',
            supplement_template_transportToPeninsula_2: '',
            supplement_template_transportSupplement_1: 'Este suplemento o abono se aplica por una petición de cambio de tipo de envío.',
            supplement_template_transportSupplement_2: '',
            supplement_template_priceChange_1: 'Hemos comprobado tu pedido y vemos que ha habido una variación de precio y el que nos aparece al efectuar al compra no coincide con el que te hemos cobrado inicialmente.',
            supplement_template_priceChange_2: '',
            supplement_template_other_1: 'Hemos comprobado tu pedido, pero vemos que el proveedor o la tienda del producto que has comprado aplica un coste de',
            supplement_template_other_2: ' Pincha en “Abonar ajuste” para ir a la pasarela de pago y abonarlo.',
            supplement_template_refund_1: '',
            supplement_template_refund_2: '',
            supplement_template_refundTransport_1: '',
            supplement_template_refundTransport_2: '',
            supplement_template_refundStore_1: '',
            supplement_template_refundStore_2: '',
            supplement_template_refundByCancelation_1: '',
            supplement_template_refundByCancelation_2: '',
            supplement_template_unified_1: 'Hemos comprobado que algún o algunos artículos de tu pedido tienen los siguientes ajustes:',
            supplement_template_unified_2: ' Pincha en “Abonar ajuste” para ir a la pasarela de pago y abonarlo.',
            supplement_template_unified_3: 'Importe total del ajuste:',
            supplement_template_unified_4: 'Te hemos hecho un reembolso por el importe final del ajuste. En los próximos días lo verás reflejado en el mismo método de pago que utilizaste al realizar el pedido.',
            supplement_template_more_info: ' Para mas información sobre cada suplemento pincha ',
            supplements_button_pay: 'Pagar suplemento',
          }
        },
        sign_up: {
          title: 'Registrarse',
          name: 'Nombre',
          last_names: 'Apellidos',
          gender: 'Sexo',
          birthday: 'Fecha de nacimiento',
          email: 'Email',
          password: 'Contraseña',
          noGenderSelected: 'Debe seleccionar una opción',
          repeat_password: 'Repetir contraseña',
          password_length: 'La contraseña debe tener al menos 8 caracteres',
          passwords_do_not_match: 'Las contraseñas no coinciden',
          friend_code: 'Codigo de amigo',
          friend_code_error: 'Codigo de amigo incorrecto porfavor introduzca uno existente',
          sign_up: 'Registrarse',
          something_wrong: 'Ups! algo salió mal. Prueba con otro correo electrónico o más tarde.',
          accepted_terms: 'Debe aceptar la Política de privacidad y los Términos y Condiciones',
          success_message: 'Se ha registrado correctamente. Le llevamos a la página de login para que pueda entrar',
          privacy_policy_text: 'Al ponerte en contacto con ELIVAPATIVA S.L. declaras haber leído y aceptado la Política de Privacidad de ELIVAPATIVA S.L. para tratar tus datos con la finalidad de atender tu solicitud de información, queja o sugerencia, relacionados con la prestación de los servicios de ELIVAPATIVA S.L. Tienes derecho a acceder, rectificar y suprimir los datos, así como otros derechos, como se explica en la Política de Privacidad. Puedes acceder al texto completo de la Política de Privacidad ',
          here: 'aquí',
          man: 'Hombre',
          woman: 'Mujer',
          other: 'Otro',
          noGender: 'Prefiero no especificarlo',
          date_error: 'Debes ser mayor de edad para poder registrarte.'
        },
        reset_password: {
          title: 'Restaurar contraseña',
          intro: 'Introduce tu nueva contraseña',
          reset_password: 'Enviar',
          success_message: 'Tu contraseña se ha guardado correctamente'
        },
        marketplaces: {
          title: 'Syniva - Tiendas'
        },
        contact: {
          contact: 'Contacto',
          time: 'Horario de atención:'
        },
        help: {
          title_back: 'Ayuda',
          title: 'Syniva - Ayuda'
        },
        legal: {
          title: 'Legal',
          privacy_policy: 'Política de privacidad',
          legal_advice: 'Aviso legal',
          cookies_policy: 'Política de cookies',
          terms_and_conditions: 'Términos y condiciones de venta'
        },
        cart: {
          title: 'Cesta',
          finishPurchase: 'Terminar de comprar',
          size: 'Talla',
          titleOrder: 'Pedido número',
          subtotal: 'Subtotal',
          syniva_price: 'Precio Syniva',
          igic: 'IGIC',
          savings: 'Te ahorras',
          shipping_insurance: 'Gestión y Seguro',
          standard_shipping: 'Envío Estándar',
          express_shipping: 'Envío Express',
          economy: 'Envío Economy',
          tax_included: '(Impuestos y gastos de aduanas incluidos)',
          total: 'Total',
          total_with_supplement: 'Total con suplemento',
          total_with_refund: 'Total con reembolso',
          select_to_add: 'Selecciona para añadir al resumen de compra',
          custom_order_price: 'Pedido personalizado',
          buy_now: 'Continuar',
          custom_marketplace: 'Pedido personalizado',
          confirm_delete_item: '¿Está seguro de eliminar este producto de su pedido?',
          nothing_in_cart: 'No hay artículos en tu cesta',
          quantity: 'Cantidad',
          payment_ok: 'Gracias, pedido realizado correctamente',
          payment_nok: 'El pedido no se ha podido realizar correctamente',
          see_order: 'Ver pedido',
          go_back_syniva: 'Volver a Syniva',
          empty_cart: 'Vaciar carrito',
          confirm_empty_cart: '¿Confirma que desea vaciar su carrito?',
          order_number_text: 'Nº de pedido:',
          switchShipModeLabel: 'Activa el "modo barco" para un obtener un coste de transporte más económico. En este caso no te descontaremos el IVA. Es la mejor opción para pedidos inferiores a 45€',
          switchShipModeTootipLabel: 'Más información',
          switchShipModeTootipInfoA: 'Activando el modo ECONOMY tu envío te sale a',
          switchShipModeTootipInfoAFree: 'Activando el modo ECONOMY tu envío te sale ',
          switchShipModeTootipInfoB: '{{economyPrice}}€',
          switchShipModeTootipInfoC: ' Aunque no te ahorres el IVA, es la',
          switchShipModeTootipInfoD: 'mejor opción para pedidos inferiores a {{shipModeMinValue}}€',
          noWeightLimitA: ' y ¡SIN LÍMITE DE PESO!,',
          noWeightLimitB: '¡GRATIS y SIN LÍMITE DE PESO!.',
          noWeightLimitC: ' y GRATIS a partir de {{freeEconomyPriceMin}}€.',
          noWeightLimitD: '¡Y SIN LÍMITE DE PESO!',
          weightLimit: '. Incluye hasta {{volValue}}kg/vol.',
          free: '¡GRATIS!.',
          discount: 'Descuento codigo promocional'
        },
        shipping_details: {
          title: 'Datos del envío',
          personal_details: 'Datos personales',
          billing_details: 'Datos de facturación',
          shipping_method: 'Método de envío',
          payment_method: 'Método de pago',
          email: 'Email',
          name: 'Nombre',
          legal_name: 'Razón social',
          contact: 'Persona de contacto',
          surnames: 'Apellidos',
          postal_code: 'Código postal',
          city: 'Ciudad',
          island: 'Isla',
          street: 'Dirección',
          number: 'Piso/Puerta',
          phone_number: 'Móvil',
          nif: 'NIF',
          cif: 'CIF',
          igic: 'IGIC',
          shipping_to_madrid: 'Gastos de envío a Madrid',
          same_shipping_billing: 'Misma dirección de facturación y envío',
          personal: 'Particular',
          company: 'Empresa',
          buy_summary: 'Resumen de compra',
          subtotal: 'Subtotal',
          syniva_price: 'Precio Syniva',
          savings: 'Te ahorras',
          shipping_insurance: 'Gestión y Seguro',
          discount_by_wallet: 'Monedero',
          custom_order: 'Pedido personalizado',
          shipping: 'Envío',
          tax_included: '(Impuestos y gastos de aduanas incluidos)',
          total: 'Total',
          buy_now: 'Comprar Syniva',
          buy_payment_method: '{{action}}',
          sequra_buy: 'Financiar con SEQURA',
          free: 'gratis',
          error_creating_payment: 'Ha ocurrido un error al crear el pago',
          express_shipping_info_peninsula: 'Hemos añadido al envío un coste adicional de {{shippingPrice}}€ que cobra la tienda por entregar tu pedido en nuestro almacén de península.',
          express_shipping_info: 'El coste del envío incluye hasta {{maxKgDefault}}kg volumétrico por pedido. Si tu paquete lo supera, nos pondremos en contacto contigo para que abones el coste adicional que suponga según la tarifa de transporte publicada.',
          express_economy_promo: 'El coste de envío incluye kg/Vol ilimitados, por lo que no tendrás que preocuparte por el peso o el volumen de tu pedido.',
          express_shipping_info_sub: 'Entrega en {{daysExpected}} laborables desde la recepción de tu pedido en nuestro almacén de península.',
          custom_order_price_info: 'Los pedidos personalizados tienen un coste de gestión extra de {{customOrderPrice}}€ pues debemos procesarlos personalmente.',
          saving_info: 'La tienda en la que estás comprando no aplica IVA en la factura, por lo que no podemos descontarlo en el importe tu pedido',
          check_for_errors: 'Comprueba que todos los datos introducidos sean correctos',
          shipping_info: 'Por motivos logísticos debemos procesar individualmente los pedidos de cada tienda.',
          save_card: 'Guardar tarjeta',
          add_new_card: 'Añadir nueva tarjeta',
          add_new_card_info: 'Se te pedirá que introduzcas los datos en la pasarela de pago tras pulsar en “COMPRAR SYNIVA”',
          save_card: 'Guardar datos de la tarjeta',
          cards: 'Tarjetas',
          promotional_codes: 'Códigos promocionales',
          promotional_codes_title: 'Añadir código promocional',
          add_balance_to_my_wallet: 'Añadir saldo de mi monedero',
          codesValidationError: 'Este código promocional no es válido',
          codesValidationErrorData: 'Faltan datos personales',
          codesValidationErrorMinimum1: 'Este código no es válido porque requiere un mínimo de',
          codesValidationErrorMinimum2: 'de compra "PRECIO SYNIVA"',
          codesValidationErrorShipment_standardShipment: 'Este código no es válido para este tipo de envío',
          codesValidationErrorShipment_onlyStandardShipment: 'Este código es válido solo para el envío STANDARD',
          codesValidationErrorShipment_onlyEconomyShipment: 'Este código es válido solo para el envío ECONOMY',
          codesValidationErrorDevice_onlyWeb: 'Este código es válido solo desde el navegador web',
          codesValidationErrorDevice_onlyMobile: 'Este código es válido solo desde dispositivos móviles',
          codesValidationErrorDevice_onlyAndroid: 'Este código es válido solo para dispositivos ANDROID',
          codesValidationErrorDevice_onlyIOS: 'Este código es válido solo para dispositivos IOS',
          code: 'Código',
          codeMarket: 'Código de tienda',
          apply: 'Aplicar',
          removeCode: 'Quitar',
          codesInfo: 'Si tienes un cupón promocional de Syniva, introdúcelo aquí:',
          codesInfoMarket: 'Si es un cupón de la tienda o marca en la que estás comprando, hazlo aquí abajo:',
          codesInfoMarket2: 'El cupón de Syniva se te aplicará automáticamente en el resumen de compra y antes de pagar el pedido. Si es de una tienda o marca, lo aplicaremos al procesar tu pedido por lo que no lo verás reflejado ahora en el resumen de compra. Una vez lo procesemos te abonaremos la diferencia.'
        },
        wallet_balance: {
          totalAvailableBalance: 'Saldo total disponible: {{amount}}',
          cannotApply: 'No cumples los requisitos para usar el monedero'
        },
        wallet: {
          title: 'Wallet',
          select_payment_method: 'Selecciona tu método de pago',
          select: 'Seleccionar',
          success: 'Método de pago seleccionado correctamente',
          error: 'Ha ocurrido un error al seleccionar el método de pago',
          no_cards: 'No tienes tarjetas guardadas',
        },
        orders: {
          actions: {
            title_cancel: '¿Quieres cancelar este pedido?',
            title_return: '¿Quieres devolver este pedido?',
            reason: 'Razón',
            reason_helper: 'Por favor, indica un motivo',
            reason_advise: 'Podrás cancelar tu pedido aunque esté procesado y si aún no ha sido enviado, abonando el coste de gestión que implica realizar el proceso de compra y devolución a la marca. Esta gestión tendrá un coste mínimo de 6,95€, y podrá verse incrementado en función del tipo de producto y la política de devolución de cada marca.',
            title_info_cancel1: '¿Estas seguro de que quieres cancelar este pedido?',
            title_info_return1: '¿Estas seguro de que quieres devolver este pedido?',
            title_info_cancel_cancelation1: '¿Estas seguro de que quieres retirar la solicitud de cancelacion?',
            cancel_order: 'Solicitar cancelación',
            cancel_cancelation: 'Retirar solicitud de cancelacion',
            return_order: 'Solicitar devolución',
            order_canceled: 'Se ha solicitado una cancelación para este pedido',
            order_returned: 'Se ha solicitado una devolución para este pedido',
            options: {
              noWantIt: 'Ya no lo quiero',
              priceChange: 'Cambio de precio',
              ivaChange: 'IVA reducido',
              tariff: 'Arancel',
              transportSup: 'Transporte a península',
              volumetricSup: 'Volumétrico',
              waitTime: 'Tiempo de espera',
              badSize: 'Talla equivocada',
              badDescription: 'No corresponde con la descripción en la web',
              noWork: 'El producto es defectuoso o no funciona',
              badProduct: 'Se ha enviado un producto equivocado',
              noStock: 'No stock'
            }
          },
          title: 'Pedidos',
          order_date: 'Fecha del pedido',
          order: 'Pedido',
          status: 'Estado',
          tracking: 'Seguimiento',
          without_tracking: 'Sin seguimiento aún',
          see_details: 'Ver detalles',
          no_orders: 'No tienes ningún pedido',
          see_order: 'Ver pedido',
          see_billing: 'Ver factura',
          cancel_order: 'Cancelar pedido',
          return_order: 'Devolver pedido',
          error_canceling_order: 'Ha ocurrido un error al enviar la petición para cancelar el pedido',
          success_canceling_order: 'La peticion de cancelación de pedido ha sido enviada correctamente',
          error_return_order: 'Ha ocurrido un error al enviar la petición para devolver el pedido',
          success_return_order: 'La peticion de devolución del pedido ha sido enviada correctamente',
          report_issue: 'Notificar incidencia',
          pay_supplement: 'Abonar ajuste',
          pay_order: 'Pagar pedido',
          paid: 'Pagado',
          supplement: 'Ajuste',
          refund: 'Reembolso',
          noTrackingUrl: 'Seguimiento aún no disponible',
          allOrders: 'Todos',
          processedOrders: 'En curso',
          canceledOrders: 'Cancelados',
          returnedOrders: 'Devueltos',
          returnStatus: 'Devolución',
          noBuyOrders: 'No comprados',
          deliveredOrders: 'Entregados',
          noBuyOrder: 'No comprado',
          noPaymentInfo: 'Haz clic encima del producto para continuar con el proceso de compra',
          canceledOrder: 'Pedido Cancelado',
          noStockOrder: 'Pedido sin stock',
          penaltyOrder: 'Pedido penalizado',
          returnedOrder: 'Pedido devuelto'
        },
        profile: {
          title: 'Mi perfil',
          address_updated: 'Dirección actualizada correctamente',
          address_error: 'Ha ocurrido un error al actualizar la dirección',
          phone_error: 'Por favor ingrese el número de teléfono correctamente',
          address_data_error: 'Asegurese de que los datos introducidos son correctos',
          modalRemoveUserTitle: '¿DESEAS ELIMINAR TU CUENTA DE FORMA PERMANENTE?',
          modalRemoveUserDescription: 'Esta acción no podrá deshacerse. Si deseas volver a abrir tu cuenta debes registrarte de nuevo.',
          modalRemoveUserCancelButton: 'Cancelar'
        },
        cookies:{
          title: 'Este sitio web utiliza cookies',
          paragraph: 'ELIVAPATIVA S.L. utiliza cookies propias y de terceros, de personalización para mejorar la calidad del producto o servicio ofrecido y de análisis para medir la audiencia y analizar el comportamiento de los usuarios. Igualmente, utilizamos cookies para medir y obtener datos de la navegación que realizas y para ajustar la publicidad a tus gustos y preferencias.',
          paragraph2: 'Puedes aceptar o rechazar el uso de cookies a continuación. Asimismo, puedes modificar tus opciones de consentimiento en cualquier momento visitando nuestra ',
          accept_cookies: 'Aceptar cookies',
          reject_cookies: 'Rechazar cookies',
          config_cookies: 'Configurar cookies',
          cancel: 'Cancelar',
          savePreferences: 'Guardar seleccionadas',
          more_info: 'Más información',
          necessary: 'Nesesario',
          preferences: 'Preferencias',
          marketing: 'Marketing',
          statistics: 'Estadísticas',
          requiredCookiesDescription: 'Son aquellas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico o la comunicación de datos',
          accessToken: 'syniva-access-token',
          accessTokenDescription: 'Cookie interna (Syniva). Funcionalidad cookie: permite disponer de una sesión iniciada para realizar acciones en la aplicación. Duración cookie: 7 días desde que es creada o actualizada.',
          refreshToken: 'syniva-refresh-token',
          refreshTokenDescription: 'Cookie interna (Syniva). Funcionalidad cookie: permite disponer de una mecanismo de refresco de sesión para no tener que realizar un nuevo inicio de sesión si el token de acceso caducó. Duración cookie: 7 días desde que es creada o actualizada.',
          platform: 'syniva-app-platform',
          platformDescription: 'Cookie interna (Syniva), solo para dispositivos móviles. Funcionalidad cookie: Permite identificar el sistema operativo que manda información con el fin de reducir la cantidad de datos, para una mejor navegación. No caduca',
          version: 'syniva-app-version',
          versionDescription: 'Cookie interna (Syniva), solo para dispositivos móviles. Funcionalidad cookie: Permite identificar la versión de la aplicación que se está ejecutando, con el fin de detectar nuevas versiones. No caduca',
          cloudFlare: '__cf_bm',
          cloudFlareDescription: 'Cookie de terceros (CloudFlare). Funcionalidad Cookie: Permite leer y filtrar peticiones que provienen de bots. Duración Cookie: 30 minutos',
          statisticsCookiesDescription: 'Son aquellas que permiten al responsable de estas el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas. La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y para la elaboración de perfiles de navegación de los usuarios de dichos sitios.',
          ga: '_ga',
          gaDescription: 'Cookie de terceros (Google). Funcionalidad cookie: registra una identificación única que se utiliza para generar datos estadísticos acerca de cómo utiliza el visitante el sitio web. Duración cookie: cookie persistente (2 años). Puedes informarte de las transferencias a terceros países que, en su caso, realizan los terceros identificados en esta política de cookies en sus correspondientes políticas (https://policies.google.com/technologies/cookies).',
          marketingCookiesDescription: '',
          NID: 'NID',
          NIDDescription: 'Cookie de terceros (Google). Funcionalidad cookie: proporcionar entrega de anuncios o retargeting, almacenar las preferencias de los usuarios. Duración cookie: 6 meses. Puedes informarte de las transferencias a terceros países que, en su caso, realizan los terceros identificados en esta política de cookies en sus correspondientes políticas (https://policies.google.com/technologies/cookies).',
          _gcl_au: '_gcl_au',
          _gcl_auDescription: 'Cookie de terceros (Google). Funcionalidad cookie: almacenar y rastrear conversiones. Duración cookie: persistente. Puedes informarte de las transferencias a terceros países que, en su caso, realizan los terceros identificados en esta política de cookies en sus correspondientes políticas (https://policies.google.com/technologies/cookies).',
          _fbp: '_fbp',
          _fbpDescription: 'Cookie de terceros (Facebook). Funcionalidad cookie: almacenar y rastrear visitas a traves de la web. Duración cookie: 3 meses. Puedes informarte de las transferencias a terceros países que, en su caso, realizan los terceros identificados en esta política de cookies en sus correspondientes políticas (https://policies.google.com/technologies/cookies).',
        },
        update_mobile: {
          update_need: 'Actualización Necesaria',
          update_info: 'Actualiza la app para tener la nueva versión y poder seguir disfrutando de tus compras con una mejor experiencia',
          update_button: 'ACTUALIZAR AHORA',
          update_hashtag: '#MejorSynIVA'
        },
        invite_friend: {
          title: 'Invita a un amigo',
          title_back: 'Invita a un amigo',
          wallet: 'Monedero',
          subtitle1: 'Consigue ',
          subtitle2: ' por cada amigo que invites',
          greatValue: '{{value}}€',
          share_code: 'Compartir código',
          subtitle_gradient: 'Gana dinero ',
          description1_a: '- Comparte el código con tus amigos para que se ahorren ',
          description1_b: ' en su primer pedido.',
          description2_a: '- Obten ',
          description2_b: ' cada vez que un amigo realice su primer pedido',
          description: 'Por cada amigo que se registre con tu código y haga como un pedido, ambos ganaréis {{amount}}€ para gastarlos en el próximo pedido.',
          how_to_button: 'Cómo funciona',
          understand: 'Entendido',
          more_info: 'Más información',
          wallet_history: 'Ver historial',
          code_copied: '¡Código copiado!',
          code_copied_extra: 'Comparte este código con tus amigos para que al registrarse lo introduzcan en el formulario de registro, así ambos ganaréis {{amount}}€ en vuestro monedero.',
          share: {
            title: ' ¡AQUÍ TIENES {{amount}}€ PARA COMPRAR EN SYNIVA!',
            // text: 'Te invito a probar Syniva con el código {{code}} ¡y podrás ganar {{amount}}€ después de tu primera compra! También puedes registrarte directamente mediante este link: {{url}}',
            text: '¡Hola! Entra en Syniva y regístrate para obtener {{amount}}€ en tu primer pedido.: {{url}}'
          },
          how_to: {
            title: 'Cómo invitar a un amigo',
            step1_title: 'Comparte tu código',
            step1_desc: '- El código que compartas será válido durante {{days}} días en compras superiores a {{value}}€.',
            step2_title: 'Tu amigo debe registrarse',
            step2_desc: '- Tu código promocional será válido durante {{days}} días en compras superiores a {{value}}€.',
            step3_title: '¡Gana dinero!',
            step3_desc: '¡HAZ NUEVOS AMIGOS Y LLENA TU MONEDERO!',
          },
          info: {
            accepted: 'Obtenido',
            expired: 'Caducado',
            modified: 'Modificado',
            benefit_amount: 'Beneficio',
            created_at: 'Fecha',
            expired_at: 'Caduca',
            name: 'Nombre',
            pending: 'Pendiente',
            status: 'Estado',
            title: 'Actividad de tu monedero',
            wallet_balance: 'Saldo actual: {{value}}€',
            used_in_order: 'Canjeado'
          }
        }
      },
      seo: {
        meta_desc: {
          home: 'Olvídate del IVA',
          marketplaces: 'Busca tu tienda favorita y olvídate del IVA',
        },
        wallet: {
          title: 'Wallet',
          select_payment_method: 'Selecciona tu método de pago',
          select: 'Seleccionar',
          success: 'Método de pago seleccionado correctamente',
          error: 'Ha ocurrido un error al seleccionar el método de pago',
          no_cards: 'No tienes tarjetas guardadas',
        },
        profile: {
          title: 'Mi perfil',
          personal_info_updated: 'Datos personales actualizados correctamente',
          personal_info_error: 'Ha ocurrido un error al actualizar los datos personales',
          billing_address_updated: 'Dirección de facturación actualizada correctamente',
          billing_address_error: 'Ha ocurrido un error al actualizar la dirección de facturación',
        }
      },
      seo: {
        meta_desc: {
          home: 'Olvídate del IVA',
          marketplaces: 'Busca tu tienda favorita y olvídate del IVA',
        }
      },
      exclusivetransport: {
        title: 'Business',
        titleInfo: 'Informacion de cliente',
        actions: 'Acciones',
        date: 'Fecha',
        created_at: 'Fecha',
        state: 'Estado',
        status_id: 'Estado',
        reference: 'Referencia',
        package_number: 'Nº bultos',
        invoice_amount: 'Importe factura',
        invoice_number: 'Numero de factura',
        delivery_note: 'Albarán',
        follow_up: 'Seguimiento',
        shipping_type: 'Tipo de envio',
        type: 'Tipo de envio',
        carrier: 'Transportista',
        shipper: 'Transportista',
        weight_vol: 'Peso Vol',
        vol_weight: 'Peso Vol',
        amount: 'Importe',
        vol_price: 'Importe',
        final_client: 'CLIENTE FINAL',
        client_name: 'Nombre',
        client_surnames: 'Apellidos',
        client_address: 'Direccion',
        client_number: 'Piso / Puerta',
        client_CP: 'CP',
        client_population: 'Isla',
        client_DNI: 'DNI',
        client_phone: 'Telefono',
        client_email: 'Email',
        addnew: 'Añadir nueva',
        close: 'Cerrar',
        send: 'Enviar',
        shipmentDataAvailable: 'Ver seguimiento',
        download_invoice: 'Descargar factura',
        download_label: 'Descargar Etiqueta Envío',
        upload_invoice: 'Subir factura',
        cancelOrder: 'Cancelar pedido',
        cancelOrderQuestion: '¿Está seguro que desea cancelar la solicitud?',
        update_error: 'Ocurrio un error editando su pedido revise las credenciales insertadas',
        success_update: 'El pedido fue editado correctamente',
        create_order_error: 'Ocurrio un error creando su pedido revise las credenciales insertadas',
        success_create_order: 'El pedido fue creado correctamente',
        upload_archive_error: 'Ocurrio un error subiendo el archivo',
        success_upload_archive: 'El archivo se subio correctamente',
        getOrder_error: 'Ups algo salio mal',
        generate_label: 'Generar etiqueta de envío',
        validation_error: 'No puede dejar ningun campo vacio. Por favor rellene el formulario correctamente',
        export_Orders: 'Exportar pedidos',
        clearFilters: 'Limpiar filtros',
        modalShipment: {
          uncompletedData: 'Datos incompletos',
          processShipment: 'Procesar envío',
          tipsaStandard: 'Tipsa estandar',
          tipsaExpress: 'Tipsa express',
          chooseShipper: 'Selecciona tipo de envío',
          close: 'Cerrar',
          generate: 'Generar etiqueta'
        },
        status: {
          required: 'Solicitado',
          labelled: 'Etiquetado',
          received: 'Recibido',
          sent: 'Enviado',
          delivered: 'Entregado',
          cancelled: 'Cancelado',
          undefined: 'Estado desconocido'
        },
        filters: {
          minDate: 'Fecha mínima',
          maxDate: 'Fecha máxima',
          dateRange: 'Seleccione un rango de fechas',
          confirmButton: 'Confirmar',
          clearButton: 'Limpiar'
        }
      },
      volumetricCalculator: {
        suplementTitle: 'Suplemento',
        bulkNumber: 'Bulto Nº',
        userInfoA: '¿Cómo funciona?',
        userInfoB: 'Solo tienes que introducir las 3 medidas de tu paquete en cm (no importa el orden) y el peso (en kg). Si son varios bultos, puedes agregarlo en “Añadir bulto” e introducir igualmente las medidas y el peso del nuevo bulto. En la primera línea podrás ver el volumen facturable de tu paquete en kg, el exceso de peso (se descuenta los kg incluidos en el pago inicial) y el suplemento que tendrías que pagar.',
        userInfoC: 'Nota:',
        userInfoD: 'Esta calculadora solo funciona para los pedidos "Economy" y "Estandar".',
        volumetricCalculator: 'Calculadora volumetrica',
        suplement: 'Suplemento (€)',
        weight: 'Peso (kg)',
        length: 'Longitud (cm)',
        height: 'Altura (cm)',
        depth: 'Fondo (cm)',
        volume: 'Volumen',
        billableVolume: 'Volumen facturable',
        weightExcess: 'Exceso de peso',
        packageManagement: 'Gestion de bultos',
        volumetricWeight: 'Peso volumetrico',
        addPackage: 'Añadir bulto'
      }
    },
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'es',

  interpolation: {
    escapeValue: false,
  },
})

export default i18n
